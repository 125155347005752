// extracted by mini-css-extract-plugin
export var chapter = "BookMenu-module--chapter--wbxuk";
export var chapterExpand = "BookMenu-module--chapterExpand--fen0I";
export var chapterHeaders = "BookMenu-module--chapterHeaders--lnTCf";
export var chapterLink = "BookMenu-module--chapterLink--Ywx4e";
export var chapterMain = "BookMenu-module--chapterMain--IYAa-";
export var chapterNumber = "BookMenu-module--chapterNumber--RfhDO";
export var chapterPaginationButton = "BookMenu-module--chapterPaginationButton--2yFeH";
export var chapterPaginationButtonLabel = "BookMenu-module--chapterPaginationButtonLabel--qO4s4";
export var chapterPaginationButtonTitle = "BookMenu-module--chapterPaginationButtonTitle--I89Db";
export var chapterTitle = "BookMenu-module--chapterTitle--BwcSG";
export var chaptersPagination = "BookMenu-module--chaptersPagination--qQQ3S";
export var root = "BookMenu-module--root--wcWGz";
export var subchapter = "BookMenu-module--subchapter--IPLCO";