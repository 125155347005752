// extracted by mini-css-extract-plugin
export var aside = "BookLayout-module--aside--PBB0H";
export var authors = "BookLayout-module--authors--sSo28";
export var authorsAvatars = "BookLayout-module--authorsAvatars--24sqc";
export var authorsTitle = "BookLayout-module--authorsTitle--ZQncK";
export var content = "BookLayout-module--content--VF8Ia";
export var contentWrapper = "BookLayout-module--contentWrapper--2TXLa";
export var fade = "BookLayout-module--fade--8RalM";
export var footer = "BookLayout-module--footer--PoJf6";
export var footerContent = "BookLayout-module--footerContent--CScOU";
export var footerCopyright = "BookLayout-module--footerCopyright--cXw+X";
export var footerWrapper = "BookLayout-module--footerWrapper--7h66S";
export var root = "BookLayout-module--root--x-lSy";
export var sidebarTitle = "BookLayout-module--sidebarTitle--Mc13I";
export var title = "BookLayout-module--title--8MGwJ";