import {useIntl} from "gatsby-plugin-react-intl";
import {Layout} from "../components/Layout";
import React, {useState} from "react";
import {
    BookLayout, BookLayoutAuthors,
    BookLayoutContent, BookLayoutFooter,
    BookLayoutSidebar,
    BookLayoutSidebarTitle,
    BookLayoutTitle
} from "../components/BookLayout";
import {
    BookMenuChapter,
    BookMenuSubchapter,
    BookMenuWrapper,
    ChapterPagination,
    ChapterPaginationButton
} from "../components/BookMenu/BookMenu";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {BookTypography} from "../components/BookTypography";
import {createSeoData} from "../functions/createSeoData";

export const query = graphql`query Book($slug: String!, $chapterSlug: String!) {
  main: contentfulBook(slug: {eq: $slug}) {
    title
    authors {
      name
      avatar {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(width: 32, height: 32, layout: FIXED)
          }
        }
      }
    }
    chapters {
      title
      slug
      content {
        childMarkdownRemark {
          headings {
            value
            depth
            id
          }
        }
      }
      extraContent {
        childMarkdownRemark {
          headings {
            value
            depth
            id
          }
        }
      }
    }
    seo {
      ...Seo
    }
  }
  chapter: contentfulBookChapter(
    book: {elemMatch: {slug: {eq: $slug}}}
    slug: {eq: $chapterSlug}
  ) {
    title
    content {
      childMarkdownRemark {
        html
      }
    }
    extraContent {
      childMarkdownRemark {
        html
      }
    }
    seo {
      ...Seo
    }
  }
}
`;

function createUrl(bookSlug, chapterSlug, isFirst) {
    const baseUrl = `/insights/${bookSlug}`;

    if (isFirst) {
        return baseUrl;

    }
    return baseUrl + '/' + chapterSlug;
}

function mergeSeoData(bookSeo, chapterSeo) {
    return {
        ...(bookSeo || {}),
        ...(chapterSeo || {})
    };
}

export default function BookPage({data, pageContext}) {
    const [expandedChapters, setExpandedChapters] = useState([pageContext.chapterSlug]);

    const currentChapterIndex = data.main.chapters.findIndex(x => x.slug === pageContext.chapterSlug);
    const nextChapter = data.main.chapters[currentChapterIndex + 1];
    const previousChapter = data.main.chapters[currentChapterIndex - 1];
    const [currentSubchapter, setCurrentSubchapter] = useState();

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(mergeSeoData(data.main.seo, data.chapter.seo))}
                   title={data.main.title}
                   defaultHrefLang={pageContext.lang}
                   lang={pageContext.lang}
                   withFooter={false}
                   companyData={pageContext.companyData}
                   hrefLangs={[pageContext.lang]}>
        <BookLayout>
            <BookLayoutSidebar>
                <BookLayoutSidebarTitle>
                    {data.main.title}
                </BookLayoutSidebarTitle>

                <BookMenuWrapper>
                    {data.main.chapters.map((x, i) => {
                        const isActive = x.slug === pageContext.chapterSlug;
                        const isExpandedByState = expandedChapters.includes(x.slug);
                        const url = createUrl(pageContext.slug, x.slug, i === 0);
                        const headings = [].concat(
                            x.content.childMarkdownRemark.headings,
                            x.extraContent?.childMarkdownRemark.headings || []
                        ).filter(x => x.depth <= 3);


                        return <BookMenuChapter key={i}
                                                url={url}
                                                active={isActive}
                                                number={i + 1}
                                                is
                                                onExpandChange={(expanded) => {
                                                    setExpandedChapters(
                                                        expanded ?
                                                            expandedChapters.concat([x.slug]) :
                                                            expandedChapters.filter(y => y !== x.slug)
                                                    );
                                                }}
                                                expandable={headings.length > 0}
                                                expanded={isExpandedByState}
                                                title={x.title}>
                            {
                                headings
                                    .map((x, i) => {
                                        return <BookMenuSubchapter key={i}
                                                                   depth={x.depth - 1}
                                                                   isActive={isActive && x.id === currentSubchapter}
                                                                   to={url + '#' + x.id}>{x.value}</BookMenuSubchapter>
                                    })
                            }
                        </BookMenuChapter>;
                    })}
                </BookMenuWrapper>
            </BookLayoutSidebar>
            <BookLayoutContent onSectionScroll={subChapter => {
                setCurrentSubchapter(subChapter);
            }}>
                <header>
                    <BookLayoutAuthors authors={data.main.authors}/>
                    <BookLayoutTitle>{data.chapter.title}</BookLayoutTitle>
                </header>
                <MarkdownContent component={BookTypography} content={data.chapter.content}/>
                <MarkdownContent component={BookTypography} content={data.chapter.extraContent}/>

                <ChapterPagination>
                    {previousChapter && <ChapterPaginationButton
                        direction="previous"
                        to={createUrl(pageContext.slug, previousChapter.slug, previousChapter === data.main.chapters[0])}
                    >
                        {previousChapter.title}
                    </ChapterPaginationButton>}
                    {nextChapter && <ChapterPaginationButton
                        direction="next"
                        to={createUrl(pageContext.slug, nextChapter.slug, false)}
                    >
                        {nextChapter.title}
                    </ChapterPaginationButton>}
                </ChapterPagination>
            </BookLayoutContent>
        </BookLayout>
        <BookLayoutFooter>
            {data.chapter.title}
        </BookLayoutFooter>
    </Layout>
}
