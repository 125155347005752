import classNames from 'classnames';
import React, {useEffect, useRef} from 'react';
import * as styles from './BookTypography.module.scss';

export function BookTypography({
                                   className,
                                   component: Component = 'div',
                                   ...otherProps
                               }) {
    const classes = classNames(
        styles.root,
        className,
    );
    const ref = useRef();
    useEffect(() => {
        if (ref.current) {
            const images = ref.current.querySelectorAll('img');
            for (const image of images) {
                image.parentNode.classList.add(styles.scrollableContainer);
            }
        }
    }, []);

    return <Component ref={ref} className={classes} {...otherProps}/>;
}
